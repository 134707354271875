<template>
  <div class="student-form-box">
    <div class="sign-out" @click="signOut" >
      <img src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/195/1/20230317/1636683116472623104.png">
      <span>退出</span>
    </div>
    <div class="top">
      <img src="https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230316/1636196768506634240.png">
    </div>
    <div class='student'>
      <el-form  :model="studentForm"  ref="ruleForm" v-loading="loading" label-width="230px" label-position="right">
        <div class="item">
          <div>
            <el-form-item label="姓名"  prop="name" :rules="[{required: true,  message: '请输入姓名',  trigger: 'blur'}]">
              <el-input v-model="studentForm.name" :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="gender" :rules="[{required: true,  message: '请选择性别',  trigger: 'change'}]">
                <el-radio-group v-model="studentForm.gender" :disabled="isDisabled">
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="0">女</el-radio>
                </el-radio-group>
              </el-form-item>
          </div>
          <div>
            <el-form-item label="民族" prop='nation' :rules="[{required: true,  message: '请输入民族',  trigger: 'blur'}]">
              <el-input v-model="studentForm.nation" :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="出生年月" prop='date_of_birth' :rules="[{required: true,  message: '请选择出生年月',  trigger: 'change'}]">
              <el-date-picker
                :disabled="isDisabled"
                v-model="studentForm.date_of_birth"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="政治面貌" prop='politics_status' :rules="[{required: true,  message: '请输入政治面貌',  trigger: 'blur'}]">
              <el-input v-model="studentForm.politics_status" :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="最高学历" prop='highest_education' :rules="[{required: true,  message: '请选择最高学历',  trigger: 'change'}]">
              <el-select v-model="studentForm.highest_education"  placeholder="请选择" :disabled="isDisabled">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="最高学位" prop='highest_offering' :rules="[{required: true,  message: '请选择最高学位',  trigger: 'change'}]">
              <el-select v-model="studentForm.highest_offering" placeholder="请选择" :disabled="isDisabled">
                <el-option
                  v-for="item in degreeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="专业技术职务" prop='professional_technical_posts' :rules="[{required: true,  message: '请输入专业技术职务',  trigger: 'blur'}]">
              <el-input v-model="studentForm.professional_technical_posts" :disabled="isDisabled"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="参加工作时间(年)" prop='work_time' :rules="[{required: true,  message: '请输入参加工作时间',  trigger: 'blur'}]">
              <el-input v-model="studentForm.work_time" :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="基层工作时间(年)" prop='substratum_work_time' :rules="[{required: true,  message: '请输入基层工作时间',  trigger: 'blur'}]">
              <el-input v-model="studentForm.substratum_work_time" :disabled="isDisabled"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="固定电话" prop='landline' :rules="[{required: true,  message: '请输入固定电话',  trigger: 'blur'}]">
              <el-input v-model="studentForm.landline" :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="移动电话" prop='mobile' :rules="[{required: true,  message: '请输入移动电话',  trigger: 'blur'}]">
              <el-input v-model="studentForm.mobile" :disabled="isDisabled"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="电子邮箱" prop='email' :rules="[{required: true,  message: '请输入电子邮箱',  trigger: 'blur'}]">
              <el-input v-model="studentForm.email" :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="邮编" prop='postcode' :rules="[{required: true,  message: '请输入邮编',  trigger: 'blur'}]">
              <el-input v-model="studentForm.postcode" :disabled="isDisabled"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="照片" prop="picture" :rules="[{required: true,  message: '请上传照片',  trigger: 'change'}]">
              <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"
                :http-request="customRequestPic"
                v-model="studentForm.picture">
                <img v-if="studentForm.picture" :src="studentForm.picture" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              
            </el-form-item>
          </div>
          <div class='row'>
            <el-form-item label="工作单位" prop='work_unit' :rules="[{required: true,  message: '请输入工作单位',  trigger: 'blur'}]">
              <el-input v-model="studentForm.work_unit" :disabled="isDisabled"></el-input>
            </el-form-item>
          </div>
          <div class='row'>
            <el-form-item label="工作部门及职务" prop='department' :rules="[{required: true,  message: '请输入工作部门及职务',  trigger: 'blur'}]">
              <el-input v-model="studentForm.department" :disabled="isDisabled"></el-input>
            </el-form-item>
          </div>
          <div class='row'>
            <el-form-item label="身份证号" prop='identity_card'  :rules="[{required: true,  message: '请输入身份证号',  trigger: 'blur'}]">
              <el-input v-model="studentForm.identity_card" :disabled="isDisabled"></el-input>
            </el-form-item>
          </div>
          <div class='row'>
            <el-form-item label="通讯地址" prop='address' :rules="[{required: true,  message: '请输入通讯地址',  trigger: 'blur'}]">
              <el-input v-model="studentForm.address" :disabled="isDisabled"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="item">
          <div class='row'>
            <div class='learn-item' v-for="(item,idx) in studentForm.cultivateList" :key="idx">
              <div>
                <el-form-item label="起止时间" :prop="'cultivateList.'+idx+'.cultivateTime'" :rules="[{required: true,  message: '请输入起止时间',  trigger: 'blur'}]">
                  <el-date-picker
                    v-model="item.cultivateTime"
                    type="daterange"
                    :disabled="isDisabled"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                   <!-- <el-date-picker
                    :disabled="isDisabled"
                    v-model="item.startTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择开始日期">
                  </el-date-picker>
                  至
                  <el-date-picker
                    :disabled="isDisabled"
                    v-model="item.endTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择结束日期">
                  </el-date-picker>
                  -->
                </el-form-item>
                <el-form-item label="学校/培训单位" :prop="'cultivateList.'+idx+'.cultivateSchool'" :rules="[{required: true,  message: '请输入学校/培训单位',  trigger: 'blur'}]">
                  <el-input v-model.trim="item.cultivateSchool" :disabled="isDisabled"></el-input>
                </el-form-item>
                <el-form-item label="所学专业/培训项目" :prop="'cultivateList.'+idx+'.cultivateMajor'" :rules="[{required: true,  message: '请输入所学专业/培训项目',  trigger: 'blur'}]">
                  <el-input v-model.trim="item.cultivateMajor" :disabled="isDisabled"></el-input>
                </el-form-item>
              </div>
              <p class="line"></p>
              <span v-if="idx !== 0" class="del-btn" @click="delLearn(idx)">x</span>
            </div>
            <div class="add-btn" @click="addLearn">
              <p>+</p>
              <p>添加主要学习培训经历</p>
            </div>
          </div> 
        </div>
        <div class="item">
          <div class='row'>
            <div class='learn-item' v-for="(item,idx) in studentForm.workExperienceList" :key="idx">
              <div >
                <el-form-item label="起止时间" :prop="'workExperienceList.'+idx+'.workExperienceTime'" :rules="[{required: true,  message: '请输入起止时间',  trigger: 'blur'}]">
                  <!-- <el-input v-model="item.workExperienceTime" :disabled="isDisabled"></el-input> -->
                  <el-date-picker
                    v-model="item.workExperienceTime"
                    type="daterange"
                    :disabled="isDisabled"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="工作单位" :prop="'workExperienceList.'+idx+'.workExperienceUnit'" :rules="[{required: true,  message: '请输入工作单位',  trigger: 'blur'}]">
                  <el-input v-model="item.workExperienceUnit" :disabled="isDisabled"></el-input>
                </el-form-item>
                <el-form-item label="部门、职务(岗位)" :prop="'workExperienceList.'+idx+'.workExperiencePost'" :rules="[{required: true,  message: '请输入部门、职务(岗位)',  trigger: 'blur'}]">
                  <el-input v-model="item.workExperiencePost" :disabled="isDisabled"></el-input>
                </el-form-item>
              </div>
              <p class="line"></p>
              <span v-if="idx !== 0" class="del-btn" @click="delWork(idx)">x</span>
            </div>
            <div class="add-btn" @click="addWork">
              <p>+</p>
              <p>添加主要工作经历</p>
            </div>
          </div> 
        </div>
        <div class="item">
          <div class='row'>
            <div class='learn-item' v-for="(item,idx) in studentForm.awardList" :key="idx">
              <div >
                <el-form-item label="荣誉名称" :prop="'awardList.'+idx+'.awardName'" :rules="[{required: true,  message: '请输入荣誉名称',  trigger: 'blur'}]">
                  <el-input v-model="item.awardName" :disabled="isDisabled"></el-input>
                </el-form-item>
                <el-form-item label="颁奖部门" :prop="'awardList.'+idx+'.awardBranch'" :rules="[{required: true,  message: '请输入颁奖部门',  trigger: 'blur'}]">
                  <el-input v-model="item.awardBranch" :disabled="isDisabled"></el-input>
                </el-form-item>
                <el-form-item label="颁奖时间" :prop="'awardList.'+idx+'.awardAdTime'" :rules="[{required: true,  message: '请输入颁奖时间',  trigger: 'blur'}]">
                  <!-- <el-input v-model="item.awardAdTime" :disabled="isDisabled"></el-input> -->
                  <el-date-picker
                    :disabled="isDisabled"
                    v-model="item.awardAdTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </div>
              <p class="line"></p>
              <span v-if="idx !== 0" class="del-btn" @click="delAward(idx)">x</span>
            </div>
            <div class="add-btn" @click="addAward">
              <p>+</p>
              <p>添加表彰奖励情况</p>
            </div>
          </div> 
        </div>
        <div class="item">
          <div class='row'>
            <el-form-item label-width="50px" prop="influence" :rules="[{required: true,  message: '请输入主要贡献、业绩和社会影响',  trigger: 'blur'}]">
              <p class="influence">（限1000字以内）</p> 
              <cus-edit :content.sync="studentForm.influence"  />
            </el-form-item>
          </div>
        </div>
        <div class="item">
          <div class='row'>
            <el-form-item label="意见表上传" label-width="180px" prop="feedback_file" :rules="[{required: true,  message: '请上传意见表',  trigger: 'change'}]">
                <commonUpload v-model="studentForm.feedback_file" @handlePreview="handlePreviews($event)" :fileList="feedbackFileList" @deleteFileItem="handleRemove('feedback_file',$event)" @customRequest="customRequest('feedback_file',$event)"></commonUpload>
            </el-form-item>
          </div>
        </div>
        <div class="item">
          <div class='row'>
            <el-form-item label="资料文件上传" label-width="200px">
              <commonUpload :fileList="fileList"  @handlePreview="handlePreviews($event)" @deleteFileItem="handleRemove('material_file',$event)" @customRequest="customRequest('material_file',$event)"></commonUpload>
            </el-form-item> 
            <p class="annex-txt">个人详细事迹材料WORD版和PDF版（需加盖公章）各1份，支撑材料1份（凸显推荐人选工作特质的图片jpg/jpeg格式电子版5幅，毕业证书、学位证书、获奖证书等证明材料扫描件）</p>
          </div>
        </div>
        <div class="footer-button">
          <div  class="submit-btn" v-if="!isDisabled" @click="submitForm(1)">提交</div>
          <div  class="footer-save submit-btn" v-if="!isDisabled" @click="submitForm(0)">保存</div>
          <div  class="footer-export submit-btn" v-if="isDisabled" @click="exportInfo">导出</div>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import CusEdit from "@/components/CusEdit";
import { getStorage } from "@/utils/storage";
import { http } from "@/api/plug_ins/plug_ins";
import commonUpload from './upload'
export default {
  data(){
    return{
      loading:true,
      isDisabled:false,
      fileList:[], // 资料文件上传
      annexList:[], // 资料文件上传
      feedbackFileList:[], // 意见表上传
      feedbackList:[], // 意见表上传
      studentForm:{
        id:'',
        operate_state:0, // 0 保存  1提交
        competitionId:'', // 评选竞赛id
        name:"", // 姓名
        gender:1, // 性别
        nation:'', //民族
        date_of_birth:'', //出生年月
        politics_status:'', //政治面貌
        highest_education:'', //最高学历
        highest_offering:'', //最高学位
        professional_technical_posts:'', //专业技术职务
        work_time:'', //参加工作时间
        substratum_work_time:'', //基层工作时间
        picture:'', //照片
        work_unit:'', //工作单位
        department:'', //工作部门及职务
        identity_card:'', //身份证号
        address:'', // 通讯地址
        landline:'', //固定电话
        mobile:'', // 移动电话
        email:'', // 电子邮箱
        postcode:'', //邮编
        cultivateList:[{ //主要学习培训经历
          cultivateTime:'',
          cultivateSchool:'',
          cultivateMajor:''
        }],
        workExperienceList:[{ //主要工作经历
          workExperienceTime:'',
          workExperienceUnit:'',
          workExperiencePost:''
        }],
        awardList:[{ // 表彰奖励情况
          awardName:'',
          awardBranch:'',
          awardAdTime:'',
        }],
        influence:'', // 主要贡献、业绩和社会影响
        certificate:'', //证书
        
        feedback_file:'',// 意见表
        
        material_file:'', //资料文件
      },
      options: [{
        value: '研究生',
        label: '研究生'
      }, {
        value: '本科',
        label: '本科'
      }, {
        value: '专科',
        label: '专科'
      }],
      degreeList:[{
        value:'学士',
        label:'学士'
      },{
        value:'硕士',
        label:'硕士'
      },{
        value:'博士',
        label:'博士'
      }],
      competitionId:''
    }
  },
  components:{
    CusEdit,
    commonUpload
  },
  created(){
    this.getData()
    
  },
  methods:{
    // 预览
    handlePreviews(info){
      console.log(info)    
      if(info.url){
        window.open(info.url)
      }
    },
    // 导出基本信息
    exportInfo(){
      console.log('导出信息')
      console.log(this.studentForm.id)
      // return
      http({
        api: "selection.competitionContent.selectionWord",
        id:this.studentForm.id,
        groupId:"",
        competitionId:this.competitionId,
        
      }).then(res=>{
        res = res.data
        if(res.code == '200'){
          let getDownloadUrl = process.env.VUE_APP_DOWNLOAD_API + res.data
          console.log(getDownloadUrl)
          const elink = document.createElement('a')
          elink.style.display = 'none'
          elink.href = getDownloadUrl
          document.body.appendChild(elink)
          elink.click()
        }else{
          return this.$message.error(res.message)
        }
        
      })
    },
    // 退出登录
    signOut() {
      http({
        api: 'admin.saas.user.logout'
      }).then(res => {
        window.localStorage.clear()
        this.$router.push('/poster')
        location.reload()
      })
    },
    // 活动列表数据
    getData() {
      http({
        api: "selection.competitionContent.activities",
        pageNum: 1,
        pageSize: 1,
        name: "",
      }).then(res=>{
        res = res.data
        if(res.code == '200'){
          this.competitionId = res.data.list[0].id
        }else{
          this.competitionId = ''
        }
        this.getStudentInfo() // 回显学生填写的信息
      }) 
    },
    // 回显学生填写的信息
    getStudentInfo(){
      this.fileList = []
      this.feedbackFileList = []
      
      http({
        api: "selection.competitionContent.selectEcho",
        competitionId:this.competitionId
      }).then(res=>{
        res = res.data
        // console.log('回显学生填写信息----------------------',res)
        if(res.code == '200'){
          this.loading = false
          res.data.cultivateList.forEach(item=>{
            if(item.cultivateStart){
              item.cultivateTime = [item.cultivateStart,item.cultivateEnd]
            }
          })
          res.data.workExperienceList.forEach(item=>{
            if(item.workExperienceStart){
              item.workExperienceTime = [item.workExperienceStart,item.workExperienceEnd]
            }
          })
          this.studentForm =  res.data
          this.isDisabled = res.data.operate_state ? true : false
          // this.studentForm.certificateList = res.data.certificate ? res.data.certificate.split(',') : []
          this.annexList = res.data.material_file ? res.data.material_file : []
          this.feedbackList = res.data.feedback_file ? res.data.feedback_file : []
          if(this.annexList.length){
            this.annexList.forEach(item=>{
              this.fileList.push({name:item.file_name,url:item.url})
            })
          }
          if(this.feedbackList.length){
            this.feedbackList.forEach(item=>{
              this.feedbackFileList.push({name:item.file_name,url:item.url})
            })
          }
        }
      })
    },
    // 添加主要学习培训经历
    addLearn(){
      this.studentForm.cultivateList.push({
        cultivateTime:'',
        cultivateSchool:'',
        cultivateMajor:''
      })
    },
    // 删除主要学习培训经历
    delLearn(val){
      this.studentForm.cultivateList.splice(val, 1)
    },
    // 添加主要工作经历
    addWork(){
      this.studentForm.workExperienceList.push({
        workExperienceTime:'',
        workExperienceUnit:'',
        workExperiencePost:''
      })
    },
    // 删除主要工作经历
    delWork(val){
      this.studentForm.workExperienceList.splice(val, 1)
    },
    // 添加表彰奖励情况
    addAward(){
      this.studentForm.awardList.push({
        awardName:'',
        awardBranch:'',
        awardAdTime:'',
      })
    },
    // 删除表彰奖励情况
    delAward(val){
      this.studentForm.awardList.splice(val, 1)
    },
    
    // 上传图片
    customRequestPic(info) {
      this.loading = true
      const formData = new FormData()
      const userInfo = getStorage('laike_admin_userInfo')
      formData.append('api', 'resources.file.uploadFiles')
      formData.append('storeId', userInfo.storeId)
      formData.append('accessId', userInfo.token)
      formData.append('uploadType',2)
      formData.append('file', info.file)
      console.log(info)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios.post(process.env.VUE_APP_PROXY_API, formData, config).then((res) => {
        res = res.data
        if (res.code == '200') {
          this.loading = false
          this.$message.success('上传文件成功')

          this.studentForm.picture = res.data.imgUrls[0]
        } else {
          this.loading = false
          return this.$message.error(res.message)
        }
      })
    },
    // 上传附件
    customRequest(val,info) {
      this.loading = true
      const formData = new FormData()
      const userInfo = getStorage('laike_admin_userInfo')
      formData.append('api', 'resources.file.uploadFiles')
      formData.append('storeId', userInfo.storeId)
      formData.append('accessId', userInfo.token)
      formData.append('uploadType',2)
      formData.append('file', info.file)
      console.log(info)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios.post(process.env.VUE_APP_PROXY_API, formData, config).then((res) => {
        res = res.data
        if (res.code == '200') {
          this.loading = false
          this.$message.success('上传文件成功')
          switch(val){
            case 'material_file':
                this.fileList.push({name:info.file.name,url:res.data.imgUrls[0]})
                this.annexList.push({file_name:info.file.name,url:res.data.imgUrls[0]})
              break;
            case 'feedback_file':
                this.feedbackFileList.push({name:info.file.name,url:res.data.imgUrls[0]})
                this.feedbackList.push({file_name:info.file.name,url:res.data.imgUrls[0]})
              break;
          }
        } else {
          this.loading = false
          return this.$message.error(res.message)
        }
      })
    },
    // 删除附件
    handleRemove(val,file) {
      let index = 0
      switch (val) {
        case 'material_file': 
            index = this.fileList.indexOf(file)
            this.annexList.splice(index,1)
            this.fileList.splice(index,1)
          break;
        case 'feedback_file':
            index = this.feedbackFileList.indexOf(file)
            this.feedbackList.splice(index,1)
            this.feedbackFileList.splice(index,1)
          break;
      }
    },
    // 提交信息
    submitForm(val){
      // this.studentForm.competitionId = this.$route.query.id
      this.studentForm.competitionId = this.competitionId
      this.studentForm.operate_state = val
      this.studentForm.material_file = this.annexList
      this.studentForm.feedback_file = this.feedbackList
      this.loading = true
      
      this.studentForm.cultivateList.forEach(item=>{
        if(item.cultivateTime && item.cultivateTime.length){
          item.cultivateStart = item.cultivateTime[0]
          item.cultivateEnd = item.cultivateTime[1]
        }
      })
      this.studentForm.workExperienceList.forEach(item=>{
        if(item.workExperienceTime && item.workExperienceTime.length){
          item.workExperienceStart = item.workExperienceTime[0]
          item.workExperienceEnd = item.workExperienceTime[1]
        }
      })
      console.log(this.studentForm)
      // 0 保存 1 是提交
      if(val == 0){
        this.submitHandle(this.studentForm)
      }else{
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.$confirm('提交成功后无法再次提交, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }).then(() => {
              this.submitHandle(this.studentForm)
            }).catch((err) => {
              this.loading = false
              console.log(err)        
            });
          } else {
            this.loading = false
            console.log('error submit!!');
            return false;
          }
        });
      }      
    },
    submitHandle(obj){
      const par = JSON.stringify(obj).replace(/\[/g, '%5B').replace(/\]/g, '%5D');
      http({
        api: "selection.competitionContent.saveOrUpdate",
        contentVo:par,
      }).then(res=>{
        this.loading = false
        res = res.data
        if(res.code == '200'){
          this.$message.success("操作成功")
          this.getStudentInfo() // 获取回显信息
          // setTimeout(()=>{
          //   this.$router.go(-1)
          // },1500)
        }else{
          return this.$message.error(res.message)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.student-form-box{
  .sign-out{
    position:absolute;
    right:30px;
    top:30px;
    color:#000;
    display:flex;
    align-items:center;
    font-size:20px;
    cursor:pointer;
    img{
      width:24px;
      height:24px;
      margin-right:6px;
    }
  }
  /deep/.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  /deep/.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  /deep/.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  /deep/.avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  background: url(https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230315/1635852005253242880.png);
  overflow: hidden;
  .top{
    img{
      display:block;
      margin:150px auto 170px;
    }
  }
  
  .student{
    width:1100px;
    margin:0 auto;
    /deep/.el-form-item__label{
      font-size:24px !important;
      color:#262626 !important;
      
    }
    /deep/.el-date-editor .el-range-input{
      text-align:left !important;
    }
    .item{
      background:#fff;
      width:100%;
      padding:60px 100px 38px 120px;
      box-sizing:border-box;
      box-shadow: 4px 12px 30px 6px rgba(0,0,0,0.09);
      position:relative;
      border-radius:20px;
      margin-bottom:170px;
      &:before{
        content:"";
        display:inline-block;
        width:374px;
        height:277px;
        position:absolute;
        left:-186px;
        top:-128px;
      }
      
      &:nth-child(1):before{
        background:url(https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230316/1636202242069159936.png) no-repeat 0 0;
        background-size:100%;
      }
      &:nth-child(2):before{
        background:url(https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230316/1636213149977468928.png) no-repeat 0 0;
        background-size:100%;
      }
      &:nth-child(3):before{
        background:url(https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230316/1636213285050834944.png) no-repeat 0 0;
        background-size:100%;
      }
      &:nth-child(4):before{
        background:url(https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230316/1636213422196187136.png) no-repeat 0 0;
        background-size:100%;
      }
      &:nth-child(5):before{
        background:url(https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230316/1636213484674539520.png) no-repeat 0 0;
        background-size:100%;
      }
      &:nth-child(6):before{
        background:url(https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230316/1636213530761551872.png) no-repeat 0 0;
        background-size:100%;
      }
      &:nth-child(7){
        margin-bottom:60px;
        &:before{
          background:url(https://fcyt-saas-oss.oss-cn-beijing.aliyuncs.com/0/1/20230316/1636213583639142400.png) no-repeat 0 0;
          background-size:100%;
        }
      }
      >div{
        display:flex;
        /deep/.el-form-item{
          margin:0 30px 46px 0 !important;
        }
        /deep/.el-input__inner{
          width:185px !important;
        }
        /deep/.desc{
          font-size:24px;
          color:#8B8B8B;
          font-weight:100;
        }
      }
      .row{
        display:block;
        /deep/.el-input__inner{
          width:620px !important;
        }
        /deep/.upload-btn{
          width: 182px;
          height: 58px;
          line-height:58px;
          background: linear-gradient(135deg, #DCB682 0%, #E4CFB2 100%);
          box-shadow: 4px 12px 30px 6px rgba(0,0,0,0.09);
          border-radius: 10px;
          border:none ;
          font-size:24px;
          color:#fff;
          margin-left:20px;
        }
        .annex-txt{
          margin-left:45px;
          color:#8B8B8B;
          font-size:24px;
          line-height:38px;
          font-weight:100;
        }
        /deep/.el-date-editor.el-input,
        /deep/.el-date-editor .el-input__inner{
          width:200px !important;
        }
      }
      .influence{
        font-size:26px;
        color:#676767;
        margin-bottom:26px;
      }
    }
    .mb20{
      margin-bottom:30px;
    }
    .first-module{
      font-size:16px;
      font-weight:bold;
    }
  }
  
  .box-card{
    margin-bottom:20px;
  }
  .learn-item{
    position:relative;
    padding-top:40px;
    &:first-child{
      padding-top:0px;
    }
    
  }
  .line{
    width: 1100px;
    height: 10px;
    background: #F9F9F9;
    margin-left:-120px;
  }
  .add-btn {
    width: 864px;
    height: 147px;
    border-radius: 6px;
    border: 1px dashed #D9D9D9;
    margin:26px auto 0;
    text-align:center;
    color:#D9D9D9;
    font-size:20px;
    cursor:pointer;
    p{
      &:first-child{
        font-size:50px;
        font-weight:100
      }
    }
  }
  .del-btn{
    display:block;
    width: 28px;
    height: 28px;
    border-radius:50%;
    border: 2px solid #BDBDBD;
    position:absolute;
    right:-80px;
    top:14px;
    color:#BDBDBD;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 100;
    font-size:22px;
    cursor:pointer;
  }
  .footer-button {
    margin-bottom:40px;
    display:flex;
    .submit-btn{
      width: 220px;
      height: 66px;
      line-height:66px;
      text-align:center;
      background: linear-gradient(135deg, #DCB682 0%, #E4CFB2 100%);
      box-shadow: 4px 12px 30px 6px rgba(0,0,0,0.09);
      border-radius: 10px;
      color:#fff;
      font-size:24px;
      margin-right:60px;
      cursor:pointer;
    }
    .footer-save{
      background: linear-gradient(90deg, #0C1443 0%, #212C71 100%);
      box-shadow: 4px 12px 30px 6px rgba(0,0,0,0.09);
    }
    .footer-export{
      background: linear-gradient(90deg, #43210C 0%, #BE6C3A 100%);
      box-shadow: 4px 12px 30px 6px rgba(0,0,0,0.09);
    }
  }
}
</style>