<template>
  <div id="container">
    <div class="login">
      <div :class="[isPlatform ? 'platform' : '', 'rotate-div']">
        <!-- 展商登录 -->
        <div class="login-box">
          <div class="login-logo" @click="changeLogin(1)">
            <img :src="logo_img" />
          </div>
          <h2>全国农商互联暨乡村振兴产销对接大会</h2>
          <div class="login-form">
            <div class="welcome">WELCOME</div>
            <div class="login-form-div">
              <h3>登录</h3>
              <el-form
                ref="merchantsForm"
                :model="merchantsForm"
                status-icon
                :rules="rules2"
                class="demo-ruleForm"
              >
                <el-form-item prop="customerNumber">
                  <el-input
                    ref="userNum"
                    v-model="merchantsForm.customerNumber"
                    placeholder="请输入展商编号"
                    type="text"
                    autocomplete="on"
                    prefix-icon="el-icon-my-shop"
                  />
                </el-form-item>
                <el-form-item prop="userName">
                  <el-input
                    ref="userName"
                    v-model="merchantsForm.userName"
                    placeholder="请输入登录账号"
                    type="text"
                    autocomplete="on"
                    prefix-icon="el-icon-my-user"
                  />
                </el-form-item>
                <el-form-item prop="pwd">
                  <el-input
                    ref="pwd"
                    v-model="merchantsForm.pwd"
                    placeholder="请输入登录密码"
                    type="password"
                    autocomplete="on"
                    prefix-icon="el-icon-my-lock"
                    show-password
                  />
                </el-form-item>
                <div style="margin-top: 56px">
                  <el-form-item>
                    <el-button
                      :loading="loading"
                      type="primary"
                      native-type="submit"
                      style="float: right"
                      round
                      @click.native.prevent="merchantsLogin('merchantsForm')"
                      >登录</el-button
                    >
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </div>
        </div>
        <!-- 平台登录 -->
        <div class="login-box">
          <div class="login-logo" @click="changeLogin(0)">
            <img :src="logo_img" />
          </div>
          <h2>全国农商互联暨乡村振兴产销对接大会</h2>
          <div class="login-form">
            <div class="welcome">WELCOME</div>
            <div class="login-form-div">
              <h3>登录</h3>
              <el-form
                ref="platformForm"
                :model="platformForm"
                status-icon
                :rules="rules1"
                class="demo-ruleForm"
              >
                <el-form-item prop="userName">
                  <!-- <el-input ref="user" v-model="platformForm.userName" placeholder="请输入登录账号" type="text" autocomplete="off" prefix-icon="el-icon-my-user" @keyup.enter.native="platformLogin" /> -->
                  <el-input
                    ref="user"
                    v-model="platformForm.userName"
                    placeholder="请输入登录账号"
                    type="text"
                    autocomplete="on"
                    prefix-icon="el-icon-my-user"
                  />
                </el-form-item>
                <el-form-item prop="pwd">
                  <!-- <el-input ref="pass" v-model="platformForm.pwd" placeholder="请输入密码" type="password" autocomplete="off" prefix-icon="el-icon-my-lock" @keyup.enter.native="platformLogin" /> -->
                  <el-input
                    ref="pass"
                    v-model="platformForm.pwd"
                    placeholder="请输入密码"
                    type="password"
                    autocomplete="on"
                    prefix-icon="el-icon-my-lock"
                    show-password
                  />
                </el-form-item>
                <div style="margin-top: 56px">
                  <el-form-item>
                    <el-button :loading="loading"
                      type="primary"
                      native-type="submit"
                      round
                      @click.native.prevent="platformLogin"
                      >登录</el-button
                    >
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer_box">
        <ul class="link-list">
          <li v-for="(item, index) in linkList" :key="index">
            <a v-if="item.url" :href="item.url" target="_blank">{{
              item.name
            }}</a>
            <span v-else>{{ item.name }}</span>
          </li>
        </ul>
        <div class="introduce">
          <span>{{ copyright_information }}</span>
          <span class="hr">版权所有</span>
        </div>
        <div class="introduce">
          <!-- <span>{{ record_information }}</span> -->
          <span><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802034296" target="_blank">京公网安备 11010802034296号</a></span>
          <span><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">京ICP备2020040306号-2</a></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validUsername } from "@/utils/validate";
import { index } from "@/api/Platform/system";
import { getShopInfo } from "@/api/Platform/merchants";
// import baseUrl from '@/utils/baseUrl'

import axios from "axios";

export default {
  data() {
    var validateUser = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入登录账号"));
      } else {
        // if (this.ruleForm.user !== "") {
        //   this.$refs.ruleForm.validateField("username");
        // }
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        // if (this.ruleForm.pass !== "") {
        //   this.$refs.ruleForm.validateField("password");
        // }
        callback();
      }
    };
    var validateUserNum = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入展商编号"));
      } else {
        // if (this.ruleForm.userNum !== "") {
        //   this.$refs.ruleForm.validateField("userNum");
        // }
        callback();
      }
    };
    var validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入登录账号"));
      } else {
        // if (this.ruleForm.name !== "") {
        //   this.$refs.ruleForm.validateField("name");
        // }
        callback();
      }
    };
    return {
      platformForm: {
        userName: "",
        pwd: "",
      },
      merchantsForm: {
        customerNumber: "",
        userName: "",
        pwd: "",
      },
      rules1: {
        userName: [{ validator: validateUser, trigger: ["blur","change"] }],
        pwd: [{ validator: validatePass, trigger: ["blur","change"] }],
      },
      rules2: {
        customerNumber: [{ validator: validateUserNum, trigger: ["blur","change"] }],
        userName: [{ validator: validateName, trigger: ["blur","change"] }],
        pwd: [{ validator: validatePass, trigger: ["blur","change"] }],
      },
      activeName: "first",
      loading: false,

      linkList: [],
      baseUrl: "",
      copyright_information: "",
      record_information: "",
      logo_img: "",
      isPlatform: 0,
    };
  },

  created() {
    axios({
      method: "post",
      url: process.env.VUE_APP_BASE_API,
      params: {
        api: "admin.system.getSetSystem",
      },
    }).then((res) => {
      if (res.data.code == "200") {
        console.log(res);
        console.log(JSON.parse(res.data.data.config.link_to_landing_page));
        const info = res.data.data.config;
        this.linkList = JSON.parse(info.link_to_landing_page);
        this.copyright_information = info.copyright_information;
        this.record_information = info.record_information;
        this.logo_img = info.logo;
      }
    });
  },

  methods: {
    // 切换登录
    changeLogin(n) {
      this.isPlatform = n;
    },
    envSetFun() {
      this.baseUrl = process.env.VUE_APP_BASE_API;
    },
    platformLogin() {
      this.$refs.platformForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("user/login", this.platformForm)
            .then((res) => {
              this.$router.push({ path: this.redirect || "/" },() => {
                this.loading = false;
              });
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    merchantsLogin(merchantsForm) {
      this.$refs["merchantsForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("user/login", this.merchantsForm)
            .then((res) => {
              if (window.localStorage.getItem('relBindURL')) {
                this.toBindMini()
                // this.loading = false
                return
              }

              // wxType 1关联 2注册 loginStep 0未完成注册或者关联 1已完成注册或者关联
              // wxType 4复用公众号 loginStep== 1 已有小程序，未设置信息 loginStep==3 注册关联完成 loginStep==0 未注册小程序
              let url = "";
              res = res.data;
              if (res.wxType === 1 && res.loginStep === 0) {
                url = "/wxthird/rel";
                // window.location.replace(baseUrl.openUrl)
              } else if (res.wxType === 2 && res.loginStep < 3) {
                url = "/wxthird/reg";
              } else if (res.wxType === 4 && res.loginStep == 0){ //跳转后端页面（复用公众号创建小程序）
                window.location.replace(process.env.VUE_APP_MINI_TO_WPP)
              } else if (res.wxType === 4 && res.loginStep == 1){ //跳转前端复用公众号创建小程序未完成小程序名称，logo，简介
                url = "/wxthird/wpp";
              } else {
                url = this.redirect || "/";
              }
              this.$router.push({ path: url }, () => {
                this.loading = false;
              });
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 解决关联操作时后端返回203 ，前端重新请求关联
    async toBindMini() {
      this.loading = true
      const obj = this.convertObj(window.localStorage.getItem('relBindURL').split('?')[1])
      const res = await http({
        api: 'customer.wx.bind.mini',
        miniName: obj.miniName,
        miniAppId: obj.miniAppId,
        miniHeadUrl: obj.miniHeadUrl,
        miniSignature: obj.miniSignature,
        principalName: obj.principalName
      })
      console.log('绑定结果输出：', res)
      this.loading = false
      if (res.data.code == 200) {
        // 如果出现203（后端token失效，需要前端处理），处理流程分别在api/https>reponse下和login>merchantsLogin方法
        localStorage.removeItem('relBindURL')
        this.$router.push('/')
      }else{
        this.$message.error(res.data.message)
      }
    },
    convertObj(str) {
      const arr = str.split('&')
      const obj = {}
      arr.forEach(value => {
        const arr_ = value.split('=')
        obj[arr_[0]] = arr_[1]
      })
      return obj
    }

  },
};
</script>

<style scoped lang="less">
#container {
  width: 100%;
  height: 100%;
}
.main {
  width: 100%;
  height: 100%;
  background-image: url(http://xiaochengxu.houjiemeishi.com/V3/images/icon1/loginBg.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: auto 100%;
  /deep/ .el-form-item__error {
    margin-left: 40px;
  }

  /deep/ .el-button {
    width: 399px;
    height: 60px;
    border-radius: 20px;
    button {
      width: 399px;
      height: 60px;
      background: linear-gradient(90deg, #06c377 0%, #06c377 100%);
      box-shadow: 0px 6px 20px 0px rgba(40, 144, 255, 0.53);
      border-radius: 20px;
    }
  }
  /deep/ .el-tabs {
    position: relative;
    top: 20px;
  }
  /deep/ .el-tabs__header {
    margin-bottom: 50px;
  }
  /deep/ .el-tabs__nav-wrap::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 420px;
    height: 2px;
    background-color: #e4e7ed;
    z-index: 1;
  }
  /deep/ #tab-first {
    width: 210px;
    text-align: center;
    height: 16px;
    // font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    // color: #999999;
  }
  /deep/ #tab-second {
    width: 200px;
    text-align: center;
  }

  /deep/ .content {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    box-sizing: border-box;
    width: 100%;
    // height: 100%;
    z-index: 999;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    el-row {
      height: 100%;
    }
  }

  .el-aside {
    // width: 57%;
    img {
      width: 100%;
    }
  }

  /deep/ .image_box img {
    width: 1103px;
    height: 526px;
    border-style: none;
  }

  /deep/ .right_form {
    // width: 800px;
    display: flex;
    box-sizing: border-box;
    flex: 1;
    flex-basis: auto;
    align-items: center;
  }

  /deep/ .form {
    margin-left: 50%;
    transform: translateX(-50%);
  }

  /deep/ .form img {
    margin-left: 50%;
    // width: 150px;
    height: 80px;
    transform: translateX(-50%);
  }

  /deep/ .form_login {
    width: 420px;
  }

  /deep/ .demo-ruleForm {
    width: 420px;
  }

  /deep/.el-form {
    .el-form-item {
      width: 400px;
      height: 60px;
      .el-form-item__content {
        margin-left: 0px !important;
        .el-input {
          width: 400px;
          height: 60px;
          input {
            width: 400px;
            height: 60px;
            border: 1px solid #cccccc;
            border-radius: 20px;
            padding-left: 42px;
          }
          .el-input__prefix {
            .el-input__icon {
              margin-top: 3px;
            }
            .el-input__icon:before {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  /deep/.el-input__inner {
    border: 1px solid #d5dbe8;
  }
  /deep/.el-input__inner:hover {
    border: 1px solid #b2bcd4;
  }
  /deep/.el-input__inner:focus {
    border-color: #409eff !important;
  }
  /deep/.el-input__inner::-webkit-input-placeholder {
    color: #97a0b4;
  }
}
.login {
  background: url("../../assets/imgs/login-bg.png") no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .rotate-div {
    position: relative;
    width: 450px;
    height: 750px;
    perspective: 2000px;
  }
  .platform .login-box:nth-of-type(2) {
    transform: rotateY(0);
  }
  .platform .login-box:nth-of-type(1) {
    transform: rotateY(180deg);
  }
  &-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 9px 9px 20px 0px rgba(86, 120, 165, 0.404);
    border-radius: 30px;
    overflow: hidden;
    transition: all 1s;

    &:nth-of-type(2) {
      transform: rotateY(-180deg);
    }
  }
  &-logo {
    width: 87px;
    height: 87px;
    margin: 35px auto 12px;
    background: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 90%;
    }
  }
  h2 {
    font-size: 24px;
    color: #06c377;
    padding-top: 13px;
    border-top: 2px solid #06c377;
    text-align: center;
    width: 68%;
    margin: 0 auto 25px;
  }
  &-form {
    background: url("../../assets/imgs/loginbox-bg.png") no-repeat;
    background-size: cover;
    height: 553px;
    &-div {
      padding: 0 70px;
    }
    .welcome {
      opacity: 0.1;
      color: #fff;
      font-size: 72px;
      line-height: 1;
      font-weight: bold;
    }
    h3 {
      font-size: 24px;
      color: #fff;
      margin-bottom: 25px;
    }
    /deep/ .el-input__inner {
      border-radius: 20px;
      overflow: hidden;
    }
    .el-button {
      float: right;
      background: #ff9a0a;
      border: 1px solid #ff9a0a;
      padding: 10px 40px;
    }
  }
}
.footer {
  width: 100%;
  height: 94px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  line-height: 1;
}

.footer .footer_box {
  text-align: center;
  width: 100%;
  height: 94px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer_box {
  ul {
    display: flex;
    li {
      &:not(:last-child)::after {
        content: "|";
        margin: 0 5px;
      }
      a {
        color: #fff;
        &:hover {
          color: #06c377;
        }
      }
    }
  }

  .introduce {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      margin: 0 5px;
      a{
        color: #fff;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .login {
    .rotate-div {
      width: 96%;
      height: 96%;
    }
    &-box {
      top: 8%;
      // transform: translateY(-50%);
      // margin-top: -50%;
    }
    &-logo {
      width: 57px;
      height: 57px;
      margin: 15px auto 10px;
    }
    h2 {
      font-size: 20px;
      padding-top: 5px;
      margin-bottom: 10px;
    }
    &-form {
      height: 400px;
      .welcome {
        font-size: 52px;
        font-weight: bold;
      }
      h3 {
        font-size: 20px;
      }
    }
  }
  .login .platform .login-box:nth-of-type(1) {
    // transform: rotateY(180deg) translateY(-50%) !important;
  }
  .footer {
    line-height: 0.8;
    bottom: -10px;
  }
}
</style>
<style lang="less">
.login {
  // 输入框logo
  .el-icon-my-shop {
    background: url("../../assets/imgs/icon-login-shop.png") no-repeat center;
    background-size: 16px 15px;
  }
  .el-icon-my-lock {
    background: url("../../assets/imgs/icon-login-lock.png") no-repeat center;
    background-size: 16px 18px;
  }
  .el-icon-my-user {
    background: url("../../assets/imgs/icon-login-user.png") no-repeat center;
    background-size: 16px auto;
  }
}
</style>
